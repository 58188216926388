@import "../../../stylesheets/variables.scss";
@import "../../../stylesheets/mixins.scss";

/*Card Styling*/
.card-inner {
  width: 300px;
 
  // @media (min-width: 600px) {
  //   width: 60%;
  // }

  @media (min-width: 1024px) {
  //  width: 30%;
  }

  .card {
    border: 1px solid #dedede;
    background-color: white;
    display: flex;
    flex-direction: column;
    width: 100%;

    .img-card {
      width: 100%;
      height: 200px;
      object-fit: cover;
    }

    .card-item {
      padding: 12px 18px 12px 18px;

      .card-title {
        font-size: 1.5em;
        margin: 0;

      }
    }

    .card-sub {
      padding: 0 18px;
    }

    .card-footer {
      padding: 18px;
      overflow: hidden;
      border-top: 1px solid #dedede;
      display: flex;
      align-items: center;
      justify-content: space-around;

      .btn {
        width: 7rem;
        border: none;
        display: inline-block;
        padding: .75rem 1.25rem;
        border-radius: 10rem;
        color: #fff;
        text-transform: uppercase;
        font-size: 1rem;
        letter-spacing: .15rem;
        transition: all .3s;
        position: relative;
        overflow: hidden;
        z-index: 1;
        cursor: pointer;

        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: $primary;
          border-radius: 10rem;
          z-index: -2;
        }

        &:before {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 0%;
          height: 100%;
          background-color: $primary-dark;
          transition: all .3s;
          border-radius: 10rem;
          z-index: -1;
        }

        &:hover {
          color: #fff;

          &:before {
            width: 100%;
          }
        }

      }
    }
  }
}

/*Card Flex Code*/



.flexible {
  flex-grow: 1;
}
.card-content{
  height: 160px;
}