@import "../../../stylesheets/variables.scss";
@import "../../../stylesheets/mixins.scss";
.progressbar-container {
  position: relative;
  width: 60%;
  height: 30px;
  border: 1px solid #FFF;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: #ecfccb;
  .progressbar-complete {
    position: absolute;
    left: 0;
    top: 0px;
    height: 100%;
    background-color: $primary-dark;
    border-radius: 10px;
    animation: g 2500ms infinite ease-in-out;
    z-index: 2;

    .progressbar-liquid {
      z-index: 1;
      width: 70px;
      height: 70px;
      animation: g 2500ms infinite ease-in-out, r 3000ms infinite cubic-bezier(0.5, 0.5, 0.5, 0.5);
      position: absolute;
      right: -5px;
      top: -10px;
      background-color: $primary-light;
      border-radius: 40%;
    }
  }
 .title-progress{
  z-index: 2;
  color: black;
  font-weight: 700;
  margin-right: 5px;
 }
  .progress {
    z-index: 2;
    color: black;
  font-weight: 700;
  }
}

@keyframes g {
  0% {
    background-color: $primary-dark;
  }

  50% {
    background-color: $primary-light;
  }

  100% {
    background-color: $primary;
  }
}

@keyframes r {
  from {
    transform: rotate(0deg);
  }

  from {
    transform: rotate(360deg);
  }
}