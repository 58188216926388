@import "../../../stylesheets/variables.scss";
@import "../../../stylesheets/mixins.scss";

.box {
  cursor: pointer;
  border-radius: 5px;
  padding: 40px;
  margin-top: 20px;
  transition: all 0.2s;
  background-color: white;
  width: 100%;
  &:hover {
    transform: scale(1.01);
  }

  &.blue {
    border-top: 3px solid $primary-dark;
  }
}

.img-establishment {
  width: 100px;
}