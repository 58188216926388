@import "../../stylesheets/variables.scss";

.container-skills {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
  background-color: $background-gray;
  .title-skills {
    font-size: 2rem;
    font-weight: bold;
    // text-shadow: 2px 2px black;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: $primary-dark;
  }

}
.carousel-container{
//  background-color: $background-gray;
  text-align: center;
}
.skills-logo{
  height: 100px;
  width: 100px;
}
.image-container { 
  width:300px;
  height:150px;
  text-align:center;
  img {
    height:100%;
  }
}

