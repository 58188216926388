@import "../../stylesheets/mixins.scss";
@import "../../stylesheets/variables.scss";

//hero class scss with background image
.hero {
  margin-top: 80px;
  background-image: url("https://images.unsplash.com/photo-1536859355448-76f92ebdc33d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=869&q=80");
  background-size: cover;
  background-position: center;
  height: 80vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  text-shadow: 2px 2px black;
  text-transform: uppercase;
  letter-spacing: 2px;
  padding: 0;
  margin: 0;
  position: relative;
  z-index: -1;
  background-color: grey;
  @media screen and (max-width: 800px) {
   height: 60vh;
  }
  .hero-container {
    max-width: 1200px;
    margin: auto;
    .hero-text {
      color: $primary-dark;

      .hero-title {
        font-size: 2rem;
        margin: 0;
        padding: 0;
        text-shadow: 2px 2px black;
        color: white;
      }
    }
  }
}