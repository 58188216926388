.footer-container {
  background-color: #f5f5f5;
  padding: 8px;
  text-align: center;
  color: #666;

  .footer-title {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 10px;
    font-family: 'Rubik', sans-serif;
  }
}