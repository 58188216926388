@import "../../stylesheets/variables.scss";

.container-projects {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
  background-color: $background-gray;

  .title-projects {
    font-size: 2rem;
    font-weight: bold;
    // text-shadow: 2px 2px black;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: $primary-dark;
  }

  .projects {
    max-width: 1000px;
    width: 100%;
    margin: auto;

    .outer {
      // display: grid;
      // column-gap: 20px;
      // row-gap: 20px;
      // width: 100%;
      // background-color: antiquewhite;
      // grid-template-columns: repeat(3, 300px);
      // justify-content: center;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      width: 100%;
      gap: 20px;
      // @media (max-width: 900px) {
      //   grid-template-columns: repeat(2, 70rem);
      // }
    }
  }
}