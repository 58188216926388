@import "../../stylesheets/variables.scss";

.contact-container {
  background-color: #e2e7e3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: 30px;

  .contact-box {
    width: clamp(100px, 90%, 1000px);
    margin: 20px 50px;
    display: flex;
    flex-direction: row;

    //flex-wrap: wrap;
    @media only screen and (max-width: 800px) {
      align-items: center;
      width: 95%;
      margin: auto;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;

    }

    .contact-links {
      background-color: #1f2e43;
      background:
        radial-gradient(circle at 55% 92%, #426691 0 12%, transparent 12.2%),
        radial-gradient(circle at 94% 72%, #426691 0 10%, transparent 10.2%),
        radial-gradient(circle at 20% max(78%, 350px), #263a53 0 7%, transparent 7.2%),
        radial-gradient(circle at 0% 0%, #263a53 0 40%, transparent 40.2%),
        #1f2e43;
      border-radius: 10px 0 0 10px;

      @media only screen and (max-width: 800px) {
        width: 70%;
        padding: 7% 9% 8% 9%;
      }


      .links {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding-top: 50px;

        .link {
          margin: 10px;
          cursor: pointer;
        }
      }
    }
  }

  .contact-form-wrapper {
    background-color: #ffffff8f;
    border-radius: 0 10px 10px 0;

    @media only screen and (max-width: 800px) {
      width: 70%;
      padding: 8% 13% 9% 5%;
    }

    .form-item {
      position: relative;
    }
  }
}









@media only screen and (max-width: 800px) {

  .contact-links,
  .contact-form-wrapper {
    width: 100%;
  }

  .contact-links {
    border-radius: 10px 10px 0 0;
  }

  .contact-form-wrapper {
    border-radius: 0 0 10px 10px;
  }
}



.title-contact {
  font-size: 2rem;
  font-weight: bold;
  // text-shadow: 2px 2px black;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: $primary-dark;
}





.img-contact {
  // width: 45px;
  //height: 45px;
  font-size: 40px;
  filter:
    hue-rotate(220deg) drop-shadow(2px 4px 4px #0006);
  transition: 0.2s;
  user-select: none;
}

.img-contact:hover {
  transform: scale(1.1, 1.1);
}

.img-contact:active {
  transform: scale(1.1, 1.1);
  filter:
    hue-rotate(220deg) drop-shadow(2px 4px 4px #222) sepia(0.3);
}



label,
input,
textarea {
  font-family: 'Poppins', sans-serif;
}

label {
  position: absolute;
  top: 10px;
  left: 2%;
  color: #999;
  font-size: clamp(14px, 1.5vw, 18px);
  pointer-events: none;
  user-select: none;
}

input,
textarea {
  width: 100%;
  outline: 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 12px;
  font-size: clamp(15px, 1.5vw, 18px);
}

input:focus+label,
input:valid+label,
textarea:focus+label,
textarea:valid+label {
  font-size: clamp(13px, 1.3vw, 16px);
  color: #777;
  top: -20px;
  transition: all .225s ease;
}

.submit-btn {
  background-color: $primary;
  filter: drop-shadow(2px 2px 3px #0003);
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: clamp(16px, 1.6vw, 18px);
  display: block;
  padding: 12px 20px;
  margin: 2px auto;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
  transition: 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.submit-btn:hover {
  transform: scale(1.1, 1.1);
}

.submit-btn:active {
  transform: scale(1.1, 1.1);
  filter: sepia(0.5);
}

@media only screen and (max-width: 400px) {
  .links {
    padding-top: 30px;
  }

  .img-contact {
    width: 38px;
    height: 38px;
  }
}

.contact-links,
.contact-form-wrapper {
  width: 50%;
  padding: 8% 5% 10% 5%;
}