@import "../../stylesheets/mixins.scss";
@import "../../stylesheets/variables.scss";
/* 
 * VARIABLES
 */

// colors
$bgColor: $primary-light;
$borderColor: #00BCD4;

// sizes
$avatarSize: 150px;
$borderWidth: 5px;
$paddingSize: 20px;

// some calculations
$translateValue: -$paddingSize - $borderWidth;

.about {
  width: 100%;
  background-color: $background-gray;
  min-width: 20vh;
  .about-container {
    max-width: 1200px;
    margin: auto;
    padding: 10px;
    .box-about {
      cursor: pointer;
      border-radius: 5px;
      padding: 30px;
      transition: all 0.2s;
      background-color: white;
      &:hover {
        transform: scale(1.01);
      }

      &.blue {
        border-top: 3px solid $primary-dark;
      }

      .about-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .about-message {
          padding: 1rem;
          width: 50%;
          display: flex;
          text-align: center;
          font-family: 'Arvo', sans-serif;
          color: #22306a;
          @media screen and (max-width: 800px) {
           width: 100%;
           }
        }
      }
    }
  }
}


//
// padding-top: 20px;
// padding-bottom: 20px;



.avatar {
  border: $borderWidth solid $bgColor;
  box-sizing: border-box;
  height: $avatarSize;
  border-radius: 50%;
  position: relative;
  width: $avatarSize;

  &__image {
    width: 100%;
    border-radius: 50%;
  }

  &::before,
  &::after {
    border: $borderWidth solid transparent;
    border-radius: 50%;
    border-top: $borderWidth solid $borderColor;
    content: "";
    display: block;
    height: calc(100% + 10px);
    left: 50%;
    width: calc(100% + 10px);
    position: absolute;
  }

  &::before {
    top: -2 * $borderWidth;
    transform: translateX(-50%);
  }

  &::after {
    bottom: -2 * $borderWidth;
    transform: translateX(-50%) rotate(180deg);
  }
}
.skeleton {
  max-width: 1200px;
  margin: auto;
  padding: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
  // justify-content: center;
  // flex-direction: column;
  // display: flex;
  // align-items: center;
}