@import "../../stylesheets/variables.scss";
@import "../../stylesheets/mixins.scss";

.education {
  width: 100%;
  background-color: $background-gray;
  margin-bottom: 30px;
  .container-education {
    max-width: 1200px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 10px;
    .title {
      font-size: 2rem;
      font-weight: bold;
      text-transform: uppercase;
      letter-spacing: 2px;
      color: $primary-dark;
    }
    .educations {
      display: flex;
      flex-direction: column;
      justify-content: center;
      background-color: $background-gray;
      width: 100%;
    }
  }
}