.card-skeleton{
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: flex-start;
  width: 100%;
}
.skeletons{
  display: flex;
  flex-direction: column;
  gap: 50px;
  justify-content: flex-start;
  width: 100%;
}
.card-skeleton-container{
  
}