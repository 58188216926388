@import "../../stylesheets/variables.scss";
@import "../../stylesheets/mixins.scss";



  .title {
    font-size: 2rem;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: $primary-dark;
  }

  .experiences {
    display: flex;
    flex-direction: column;
    // flex-wrap: wrap;
    // align-items: center;
    justify-content: center;
    background-color: $background-gray;
    width: 100%;

  }
