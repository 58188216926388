@import "../../stylesheets/variables.scss";
@import "../../stylesheets/mixins.scss";

.navbar {
  height: 80px;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 999;
  transition: all .3s ease;
  padding: 10px;
  @media screen and (max-width: 800px) {
    background-color: white;
  }
  .nav-container {
    max-width: 1200px;
    margin: auto;
    display: flex;
    align-items: center;
    height: 100%;

    .nav-logo {
      flex: 1;
      font-family: 'Rubik', sans-serif;
      color: white;
      @media screen and (max-width: 800px) {
        color: $primary-dark;
      }
    }

    .hamburger {
      height: 40px;
      width: 40px;
      border-radius: 50%;
      color: $white;
      background-color: $primary;
      display: none;
      text-align: center;
      margin-left: auto;
      cursor: pointer;
      padding: 0.5rem;
      transition: background-color 0.2s ease-in-out;
      position: absolute;
      top: 50%;
      right: 25px;
      transform: translateY(-50%);

      @media screen and (max-width: 800px) {
        display: block;
      }
    }

    .navigation-menu {
      display: flex;
      align-items: center;
      gap: 20px;

      .link {
        color: white;
        cursor: pointer;
        font-family: 'Rubik', sans-serif;
        @media screen and (max-width: 800px) {
          color: #000;
          font-family: 'Rubik', sans-serif;
          position: relative;
          transition: all 0.4s ease;
          line-height: 20px;
        }
        &:hover {
          color: $primary-dark;
          font-family: 'Rubik', sans-serif;
        }
      }

      .active {
        transition: width 0.4s ease;
        -moz-transition: 0.4s ease;
        -webkit-transition: 0.4s ease;
        -o-transition: 0.4s ease;
        font-family: 'Rubik', sans-serif;
        color: $primary-dark;
      }
    }
  }
}

.navbar--fixed {
  box-shadow: 0 2px 2px 2px rgba(9, 9, 9, 0.23);
  border-bottom: 1px solid #e5e5e5;
  background-color: #fff;

  .nav-container {
    .nav-logo {
      color: $primary-dark;
    }

    .navigation-menu {

      //*************************************LINK **************************************//
      .link {
        color: #000;
        font-family: 'Rubik', sans-serif;
        position: relative;
        transition: all 0.4s ease;
        line-height: 20px;
      }

      .link:hover {
        color: $primary-dark;
      }

      .link::before {
        content: '';
        width: 0px;
        height: 0px;
        position: absolute;
        top: 100%;
        left: 0;
        background: #22306a;
        transition: 300ms;
        width: 0%
      }

      .link::after {
        content: '';
        width: 0px;
        height: 2px;
        position: absolute;
        top: 100%;
        left: 0;
        background: $primary-dark;
        transition: 300ms;
        color: $primary-dark;
      }

      .link:hover::after {
        width: 100%;
        color: $primary-dark;
      }

      //*************************************ACTIVE LINK **************************************//
      .active {
        font-family: 'Rubik', sans-serif;
        color: $primary-dark;
      }

      .active::after {
        content: '';
        width: 100%;
        height: 2px;
        position: absolute;
        top: 100%;
        left: 0;
        background: $primary-dark;
        transition: 300ms;
        color: $primary-dark;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .navbar {
    padding: 10px;
      .nav-container {
        .navigation-menu {
          display: none;
          /* navigation menu is positioned to start 60px from the top of the document (which is directly below the navbar)*/
          position: absolute;
          top: 70px;
          right: 0;
          /* stacks the li tags vertically */
          flex-direction: column;
          /* makes menu span full height and width*/
          width: 100%;
          height: calc(100vh - 77px);
          background-color: white;
          border-top: 1px solid black;
          padding-top: 20px;
        }

        .expanded {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          gap: 20px;

        }
      }
    }
}